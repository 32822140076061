.courseTile {
  text-align: center;
  display: block;
  /* margin: 10px; */
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  transition: 0.1s linear;
  width: 80px !important;
}
.courseTile:hover {
  box-shadow: 10px 10px 100px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.navbar_item:hover {
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.2s ease-in;
}

.active_tab {
  color: #42cafd !important;
  font-weight: bold !important;
  background: #ffffff !important;
  border-radius: 1em !important;
}


/* https://coolors.co/42cafd-66b3ba-8eb19d-f6efa6-f0d2d1  */

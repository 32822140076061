.skills_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
}

/* images on top of Top skill each category */
.skills_img {
  width: auto !important;
}
/* @media only screen and(max-width: 660px) {
  .skills_img {
    width: 100% !important;
    padding: 0 1em;
  }
} */

.skills_card:hover {
  border: 0.2rem solid #d0d0d0;
  cursor: default;
  transition: all 0.1s ease-out;
  border-radius: 1%;
  box-shadow: 10px 10px 100px rgba(0, 0, 0, 0.2);
  scale: 1.05;
}

.sub_container {
  margin-top: 2em;
  padding: 1em;
}

.skills_card img {
  padding-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

#container {
  display: flex;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
  text-align: center;
  position: relative !important;
}

/* Introduction that is overlayed on canvas */
#myContext {
  position: absolute !important;
  transform: translate("-50%", "-50%");
  top: 30%;
  color: white;
  font-weight: lighter !important;
  z-index: 1;
}

#canvas {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
}

#main_container {
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;
  height: 100% !important;
}

/* Handles the image ratio */
#main_container img {
  width: 50%;
  height: 50%;
}

.section_title_and_image {
  text-align: center !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.section_title_and_image img {
  margin-right: 1em;
}

/* A small svg element infront of title */
#small_svg {
  width: 10% !important;
}

/* An icon at the beginning of sentece */
#small_svg_cmd {
  width: 5% !important;
  margin-right: 5%;
}

.skills_svg {
  padding: 10%;
}

/* Svg images that are under the Programming section */
.skills_svg:hover {
  border: 0.2rem solid #d0d0d0;
  cursor: pointer;
  transition: all 0.1s ease-out;
  border-radius: 5%;
  box-shadow: 10px 10px 100px rgba(0, 0, 0, 0.2);
  scale: 1.05;
}

/* A container for skills svgs */
#skills_container {
  display: flex;
  flex-direction: row;
  text-align: center;
}
